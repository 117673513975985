import * as R from 'ramda'
import * as ReactRouter from 'react-router-dom'
import * as React from 'react'
import PropTypes from 'prop-types'

import * as t from '@rushplay/theme'
import * as common from '@rushplay/common'
import * as emotion from '@emotion/core'
import styled from '@emotion/styled'

import { useGameInfoUrlQuery } from '../use-game-info-url-query'

export function baseStyles(props) {
  // I'm writing them manually because you can't call functions inside css
  return emotion.css`
    display: flex;
    align-items: center;
    font-size: ${props.theme.fontSizes[3]}px;
    padding: 14px;
    font-weight: bold;

    user-select: none;

    &.active {
      color: ${props.theme.colors['selected-menu-item']};
    }

    &:hover,
    &:active,
    &.active {
      background-color: transparent;
      color: ${props.theme.colors['selected-menu-item']};
    }

    &:active,
    &.active {
      position: relative;
      
      &::before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 6px;
        height: 100%;
        background: ${props.theme.colors['selected-menu-item']};
        border-radius: 0 4px 4px 0;
      }
    }
  `
}

const DisabledMenuLink = styled.span`
  ${baseStyles};
  color: ${t.color('g-text', 0.3)};
  cursor: not-allowed;

  &:hover,
  &:active,
  .active {
    color: ${t.color('g-text', 0.3)};
    background-color: initial;
    box-shadow: initial;
  }

  &.active {
    color: ${t.color('g-text', 0.3)};
  }
`

const MenuLink = styled(ReactRouter.NavLink)`
  ${baseStyles};
  color: ${t.color('g-text')};

  &.active {
    color: ${t.color['selected-menu-item']};
  }
`

export function MenuItem(props) {
  const Icon = props.icon
  const gameInfoQuery = useGameInfoUrlQuery()

  if (props.disabled) {
    return (
      <DisabledMenuLink data-testid={props.testId}>
        <Icon />
        <common.Box
          fontSize={3}
          paddingLeft={1}
          display="inline-flex"
          fontFamily="head"
          position="relative"
        >
          {props.children}
        </common.Box>
      </DisabledMenuLink>
    )
  }
  return (
    <MenuLink
      data-testid={props.testId}
      to={props.to}
      isActive={match => {
        if (gameInfoQuery.referrer) {
          return (
            R.equals(gameInfoQuery.referrerPath, props.to) ||
            R.equals(`/${gameInfoQuery.referrer}`, props.to)
          )
        }
        // We don't want loyalty-program to be active
        if (R.test(/loyalty-program=cashback/, props.to)) {
          return
        }

        return match
      }}
      exact={props.exact}
      onClick={props.onClick}
    >
      <Icon />
      <common.Box
        fontSize={3}
        paddingLeft={1}
        display="inline-flex"
        fontFamily="head"
        position="relative"
      >
        {props.children}
      </common.Box>
    </MenuLink>
  )
}

MenuItem.propTypes = {
  children: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  exact: PropTypes.bool,
  icon: PropTypes.func.isRequired,
  testId: PropTypes.string,
  to: PropTypes.string.isRequired,
  onClick: PropTypes.func,
}

export default MenuItem
